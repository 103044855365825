//import css
import "./scss/main.scss";

//import components js
import "./components/backtotop/backtotop.js";
import "./components/banner/banner.js";
import "./components/breadcrumbs/breadcrumbs.js";
import "./components/card-3-cols/card-3-cols.js";
import "./components/card-category/card-category.js";
import "./components/card-feature/card-feature.js";
import "./components/collapse-component/collapse-component.js";
import "./components/contact-form/contact-form.js";
import "./components/download-component/download-component.js";
import "./components/filter-row/filter-row.js";
import "./components/footer/footer.js";
import "./components/h-card/h-card.js";
import "./components/header/header.js";
import "./components/hero/hero.js";
import "./components/home-banner/home-banner.js";
import "./components/home-hero/home-hero.js";
import "./components/nav/nav.js";
import "./components/news-card/news-card.js";
import "./components/news-slider/news-slider.js";
import "./components/product-card/product-card.js";
import "./components/product-intro-text/product-intro-text.js";
import "./components/product-list/product-list.js";
import "./components/related/related.js";
import "./components/section/section.js";
import "./components/skiplinks/skiplinks.js";
import "./components/specs-list/specs-list.js";
import "./components/table-responsive/table-responsive.js";
import "./components/text-reveal/text-reveal.js";
import "./components/title-text/title-text.js";

import "./js/browser-detect";
import "./js/lazyload";

import '@splidejs/splide/css/core';

